/* requires:
polyfill.js
*/

const auth = {
  apiDomain: 'cart_domain_replaced_during_build',
  authUrl: 'cart_domain_replaced_during_build' + '/request/userApi/login',
  logoutUrl: 'cart_domain_replaced_during_build' + '/request/userApi/logout',
  checkAuthUrl: 'cart_domain_replaced_during_build' + '/request/userApi/getUserStatusAndName',
  dashboardUrl: 'cart_domain_replaced_during_build' + '/Dashboard',

  init: (formId) => {
    const theForm = document.getElementById(formId);

    auth.checkAuth();
    auth.validateOnBlur(theForm);

    const submitBtn = theForm.querySelector('button[type="submit"]');
    submitBtn.addEventListener(
      'click',
      function (e) {
        e.preventDefault();
        const isFormValid = auth.isFormValid(theForm);
        if (isFormValid) {
          auth.login(theForm);
        }
      },
      true
    );
  },

  checkAuth: () => {
    const options = {
      credentials: 'include',
      mode: 'cors',
    };

    function getInitials(name) {
      return name
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('');
    }

    fetch(auth.checkAuthUrl, options)
      .then(checkFetchStatus)
      .then((res) => {
        // console.log(res);
        if (res && res?.data?.isLoggedIn) {
          // console.log('User Is Logged In!');

          const userFullname = res.data.fullName;
          const userInitials = getInitials(res.data.fullName);

          const mobileLogin = document.getElementById('mobile-login');
          const mobileUser = document.getElementById('mobile-user');
          const desktopLogin = document.getElementById('desktop-login');
          const desktopUser = document.getElementById('desktop-user');

          document.body.classList.add('authenticated');

          mobileLogin.classList.add('d-none');
          mobileLogin.classList.remove('d-block');
          mobileUser.classList.add('d-block');
          mobileUser.classList.remove('d-none');

          desktopLogin.classList.add('d-xxl-none');
          desktopLogin.classList.remove('d-xxl-block');
          desktopUser.classList.add('d-xxl-block');
          desktopUser.classList.remove('d-xxl-none');

          const userInitialsCollection = desktopUser.querySelectorAll('.user-initials');
          for (let el of userInitialsCollection) {
            el.innerHTML = userInitials;
          }

          const userFullnameColection = desktopUser.querySelectorAll('.user-fullname');
          for (let el of userFullnameColection) {
            el.innerHTML = userFullname;
          }
        } else {
          // console.log('User Not Logged In!');
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  login: (theform) => {
    const formData = new FormData(theform);
    const alertMsg = theform.querySelector('.invalid-login');

    // ?params={"userName":"username","password":"111111","remember-username":"true"}
    const params = {
      params: JSON.stringify({
        userName: formData.get('userName'),
        password: formData.get('password'),
        'remember-username': formData.get('remember-username'),
      }),
    };

    // const params = {
    //   userName: formData.get('userName'),
    //   password: formData.get('password'),
    //   'remember-username': formData.get('remember-username'),
    // };

    const serializedFormData = new URLSearchParams(params);

    const options = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: serializedFormData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };

    fetch(auth.authUrl, options)
      .then(checkFetchStatus)
      .then((res) => {
        // console.log(res);
        // console.log(res?.data?.authenticated);
        if (res && res?.data?.authenticated) {
          // console.log('Succefully Logged In!');
          alertMsg.classList.remove('d-block');
          alertMsg.classList.add('d-none');

          // redirecting user to the dashboard
          window.location.href = auth.dashboardUrl;
        } else {
          alertMsg.classList.remove('d-none');
          alertMsg.classList.add('d-block');
          // console.log('Invalid Login!');
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  logout: () => {
    const options = {
      credentials: 'include',
      mode: 'cors',
    };

    fetch(auth.logoutUrl, options)
      .then(checkFetchStatus)
      .then((res) => {
        // console.log(res);
        if (res && res?.success) {
          // console.log('Succefully Logged Out!');
          window.location.href = auth.apiDomain;
        } else {
          // console.log('Not Logged Out!');
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  validateOnBlur: (theForm) => {
    if (!theForm) return false;

    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll('.required');
    for (let field of fields) {
      field.addEventListener(
        'blur',
        function (e) {
          const x = field.value;
          if (!x || x.trim() == '') {
            field.classList.add('is-invalid');
          } else {
            field.classList.remove('is-invalid');
          }
        },
        true
      );
    }
  },

  isFormValid: (theForm) => {
    if (!theForm) return false;

    // form fields validation during submission
    const fields = theForm.querySelectorAll('.required');
    let isFormValid = true;
    for (let field of fields) {
      const x = field.value;
      if (!x || x.trim() == '') {
        field.classList.add('is-invalid');
        isFormValid = false;
      } else {
        field.classList.remove('is-invalid');
      }
    }
    return isFormValid;
  },
};
