/* requires:
polyfill.js
*/

// https://www.statefoodsafety.com/request/cartApi/addCartProducts?params={"cartProducts":[{"productId":15,"categoryItemId":null,"proctorProductId":null,"upsellSourceProductId":15,"isUpsellSource":true,"ignoreExistingProctoring":true},{"productId":3409,"categoryItemId":null,"proctorProductId":null,"upsellSourceProductId":15,"isUpsellSource":false,"ignoreExistingProctoring":true}]}

// example params for adding products to cart
// const params = {
//   cartProducts: [
//     {
//       productId: 47,
//       quantity: 1,
//       categoryItemId: null,
//       proctorProductId: null,
//       upsellSourceProductId: 47,
//       isUpsellSource: true, // this means this is the main product
//       ignoreExistingProctoring: true,
//     },
//     {
//       productId: 4253,
//       quantity: 1,
//       categoryItemId: null,
//       proctorProductId: null,
//       upsellSourceProductId: 47,
//       isUpsellSource: false, // this means the main product is the upsellSourceProductId above
//       ignoreExistingProctoring: true,
//     },
//   ],
// };

const cart = {
  apiBaseUrl: 'cartAPI_url_replaced_during_build',
  cartUrl: 'cart_domain_replaced_during_build' + '/CartForm',

  init: () => {
    cart.fetchCart();
    cart.initAddBtnEvent();
    cart.initAddToCartForm();
  },

  fetchCart: () => {
    const url = `${cart.apiBaseUrl}/getProductList?retrieveMessages=true`;

    fetch(url, { credentials: 'include', mode: 'cors' })
      .then(checkFetchStatus)
      .then((data) => {
        // console.log('Fetched Cart');
        // console.log(data);
        if (data && data?.data?.length > 0) {
          // console.log('fetchedCart');
          // console.log(data.data);
          cart.updateCartItemCount(data.data);
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  addToCart: (cartData) => {
    const url = `${cart.apiBaseUrl}/addCartProducts?params=${cartData}`;
    fetch(url, { credentials: 'include', mode: 'cors' })
      .then(checkFetchStatus)
      .then((data) => {
        console.log('AddToCart Called');
        console.log(data);
        if (data && data?.data?.totalCount > 0) {
          // console.log('Products Added To Cart');
          // console.log(data.data.lineItems);
          cart.updateCartItemCount(data.data.lineItems);

          // redirecting user to the cart after adding the product/s
          window.location.href = cart.cartUrl;
        } else {
          console.log('There were no products added to cart');
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  updateCartItemCount: (cart) => {
    const itemCount = cart.reduce((previousItem, currentItem) => previousItem + currentItem.quantity, 0);
    const viewCartLink = document.querySelectorAll('a.view-cart');
    for (let cartLink of viewCartLink) {
      const itemCountDisplay = cartLink.querySelector('.item-count');
      if (itemCount) {
        cartLink.classList.add('has-product');
        itemCountDisplay.dataset.qty = itemCount || 0;
      } else {
        cartLink.classList.remove('has-product');
        delete itemCountDisplay.dataset.qty;
      }
    }
  },

  initAddBtnEvent: () => {
    document.addEventListener(
      'click',
      (event) => {
        const eventElement = event.target;
        const addToCartBtn = eventElement.closest('.add-to-cart-btn');
        const addToCartFormBtn =
          eventElement.closest('.add-to-cart-form') &&
          eventElement.closest('.add-to-cart-form').querySelector('.add-to-cart-btn');
        // addToCartBtn.classList.remove('btn-processing');
        if (
          addToCartBtn &&
          !addToCartFormBtn &&
          !addToCartBtn.classList.contains('go-to-cart') &&
          !addToCartBtn.classList.contains('view-cart-btn')
        ) {
          event.preventDefault();
          // addToCartBtn.classList.add('btn-processing');
          const cartData = addToCartBtn.dataset.cart;
          cart.addToCart(cartData);
        }
      },
      true
    );
  },

  initAddToCartForm: () => {
    document.addEventListener(
      'submit',
      (event) => {
        event.preventDefault();
        const eventElement = event.target;
        const addToCartForm = eventElement.closest('.add-to-cart-form');
        if (addToCartForm) {
          const qtyInputValue = addToCartForm.querySelector('.qty-input').value;
          const productId = addToCartForm.querySelector('[name="productId"]').value;
          const cartData = {
            cartProducts: [
              {
                productId: productId,
                quantity: qtyInputValue,
              },
            ],
          };
          cart.addToCart(JSON.stringify(cartData));
        }
      },
      true
    );
  },
};

cart.init();
