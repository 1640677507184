/* requires:
polyfill.js
*/

// display the current year in the footer
document.querySelector('.footer-year').innerHTML = new Date().getFullYear();

// making sure the external links are opening in a new window
window.onload = function () {
  var anchors = document.getElementsByTagName('a');
  for (var i = 0; i < anchors.length; i++) {
    if (anchors[i].hostname != window.location.hostname) {
      anchors[i].setAttribute('target', '_blank');
    }
  }
};

// initializing tooltip needed by bootstrap
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
if (tooltipTriggerList && tooltipTriggerList.length) {
  [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
}
